<template>
  <div id="app" :class="lang">
    <Navbar :showLogo="showLogo" />
    <router-view :showLogo="showLogo" :scrollY="scrollY"></router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Layout/NavBar.vue'
import Footer from '@/components/Layout/Footer.vue'
import { getLang } from '@/utils/lang'

export default {
  name: 'app',
  components: { Navbar, Footer },
  data() {
    return {
      showLogo: false,
      scrollY: 0,
      lang: ''
    }
  },
  watch: {
    '$i18n.locale'(newValue) {
      this.lang = newValue
    }
  },
  created() {
    let lang = getLang()
    // if (!localStorage.getItem('language')) {
    const hostname = location.hostname
    if (hostname && hostname.indexOf('en.') !== -1) {
      localStorage.setItem('language', 'en')
    } else if (hostname && hostname.indexOf('zh.') !== -1) {
      localStorage.setItem('language', 'zh')
    } else if (hostname && hostname.indexOf('www.') !== -1) {
      localStorage.setItem('language', 'ja')
    } else if (hostname && hostname.indexOf('zh-tw.') !== -1) {
      localStorage.setItem('language', 'zhf')
    }
    // }
    console.log('缓存', localStorage.getItem('language'))
    this.$i18n.locale = localStorage.getItem('language') || lang
    this.lang = localStorage.getItem('language') || lang
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    localStorage.removeItem('language')
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY
      this.showLogo = window.scrollY >= document.documentElement.clientHeight / 2
    }
  }
}
</script>

<style lang="scss">
#app {
  user-select: none;
  overflow-x: hidden;
}
.en {
  font-family: Inter;
}
.zh {
  font-family: 'Source Han Mono SC';
}
.zhf {
  font-family: 'Source Han Mono TC';
}
.ja {
  font-family: irohamaru;
}
</style>
